import React, { useEffect, useState } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { CompaniesProps } from './interface';
import { useHistory } from 'react-router-dom';
import { CompanyDetails, ViewCompanyDetails } from './components';
import {
  List,
  Notification,
  Modal,
  Template,
  TempMessage,
} from 'business/modules/admin/common';
import { MdDelete } from 'react-icons/md';
import { RxMagnifyingGlass } from 'react-icons/rx';
import { HiOutlinePlus } from 'react-icons/hi';
import { MdDone } from 'react-icons/md';
import { MdErrorOutline } from 'react-icons/md';
import './styles.scss';

const Companies: React.FC<CompaniesProps> = ({
  getTheCompaniesList,
  setDeleteFlagState,
  companyWasDeleted,
  loading,
  handleCompaniesListCounterAndOrder,
  orderedList,
  orders,
  companiesIntendedCounter,
  companiesCounter,
  updateOrderState,
  setIsACompanyEdition,
  defineCompanyToEdit,
  saveCompItemGlobally,
  modalMustClose,
  handleModalState,
  compItem,
  deleteACompany,
  loadingErrors,
}) => {
  const history = useHistory();
  const [companies, setCompanies]: any = useState([]);
  const [loadedCompanies, setLoadedCompanies] = useState<boolean>(false);
  const [initList, setInitList]: any = useState([]);
  const [current, setCurrent] = useState('');
  const [unorderedDefault, setUnorderedDefault] = useState([]);

  const incremented: any = {
    name: companiesIntendedCounter.name + 1,
    location: companiesIntendedCounter.location + 1,
    code: companiesIntendedCounter.code + 1,
    instCount: companiesIntendedCounter.instCount + 1,
  };

  const addCompany = () => {
    history.push(`/admin/dashboard/companies-list/company/add`);
  };

  const handleView = (item: any) => {
    history.push(`/admin/dashboard/companies-list/company/edit`);
  };

  const handleViewInstallers = (item: any) => {
    //TODO: call the specific list of installers for the current item / COMPANY

    history.push(`/admin/dashboard/companies-list/company/installers`);
  };

  const getTheList = async () => {
    loading.start('Loading Companies List...');
    let response: any = await getTheCompaniesList();

    if (response && response?.statusCode === 200) {
      if (response?.body?.data && response?.body?.data?.length > 0) {
        setCompanies(response?.body?.data);

        setInitList(response?.body?.data);

        setLoadedCompanies(true);
      }
    } else if (response && response?.statusCode !== 200) {
      //TODO handle error
      setLoadedCompanies(true);
    }

    loading.stop();
  };

  const deleteCompany = async (id: any) => {
    let response: any = await deleteACompany({ id: id });
    if (response?.statusCode === 200 && response?.result) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.delete?.success,
        type: 'success',
      });
    } else {
      Notification({
        title: 'Warning!',
        message: config?.notifications?.delete?.error,
        type: 'warning',
      });
    }

    await getTheList();
  };

  const performAction = (id: string) => {
    switch (id) {
      case `view-company1`:
        return () => {};
      case `delete-company-modal-id`:
        return del();
      default:
        break;
    }
  };

  const del = () => {
    deleteCompany(compItem?.company_id);
  };

  const returnAccordionDetails = (item: any) => {
    return <CompanyDetails item={item} />;
  };

  useEffect(() => {
    getTheList();
  }, []);

  useEffect(() => {
    if (companyWasDeleted) {
      getTheList();

      setTimeout(() => {
        setDeleteFlagState(false);
      }, 1205);
    }
  }, [companyWasDeleted]);

  //preserves the default order
  useEffect(() => {
    if (initList && initList.length > 0) {
      let savedDefaultArr: any = [];
      initList.forEach((item: any) => {
        savedDefaultArr.push(item);
      });
      setUnorderedDefault(savedDefaultArr);
    }
  }, [initList]);

  //sets the current order criteria to pass it to the child component
  useEffect(() => {
    if (orders.orderByLocation) {
      setCurrent(`location`);
    } else if (orders.orderByCode) {
      setCurrent(`code`);
    } else if (orders.orderByName) {
      setCurrent(`name`);
    } else if (orders.orderByInstCount) {
      setCurrent(`instCount`);
    } else if (orderedList && loadedCompanies && unorderedDefault.length > 0) {
      setCurrent(``);
    }
  }, [orders, companiesIntendedCounter, companiesCounter, orderedList]);

  const classes: any = {
    class1: `modal fade`,
    class2: `modal-dialog`,
    role: `dialog`,
    class3: `modal-content`,
  };
  return (
    <Template
      id="companies-list"
      title={config.strings.title1}
      hasRightBtn={true}
      fun={addCompany}
      icon={<HiOutlinePlus className="svg-refresh" />}
    >
      {loadingErrors && loadingErrors?.companies?.length > 0 ? (
        <div id="errors-display--comp">
          <div className="alert alert-info" role="alert">
            <MdErrorOutline className="alert-icon" />
            <span className="lead err-string">{loadingErrors?.companies}</span>
          </div>
        </div>
      ) : (
        <List
          rowClasses={config.rowClasses}
          id="companies-accordion"
          list={companies}
          loaded={loadedCompanies}
          itemsPerPage={10}
          manageListOrder={handleCompaniesListCounterAndOrder}
          orderedList={orderedList}
          orders={orders}
          sectionCounter={companiesIntendedCounter}
          sectionCount={companiesCounter}
          updateOrderState={updateOrderState}
          incremented={incremented}
          current={current}
          unorderedDefault={unorderedDefault}
          handles={{
            handleView,
          }}
          config={config}
          secondFun={(item: any) => {
            handleView(item);
            setIsACompanyEdition(true);
            defineCompanyToEdit(item);
          }}
          fourthFunction={(item: any) => handleViewInstallers(item)}
          setItem={(item: any) => {
            saveCompItemGlobally(item);
          }}
          retrieveColValue={functions.retrieveColValue}
          returnAccordionDetails={returnAccordionDetails}
        />
      )}

      <TempMessage
        id={`copy--clip--launch`}
        closeBtn={`close1`}
        title={
          <span className="success--alert">
            Success! <MdDone style={{ marginBottom: '2px' }} />
          </span>
        }
        text={`Copied to clipboard.`}
        closeId={`close--alert--clipboard`}
        classnames={classes}
      />

      <Modal
        config={config}
        id={`delete-company-modal-id`}
        title={config.modal.delete.title}
        icon={<MdDelete className="edit" />}
        body={config?.modalStrings?.aboutToDel}
        onClick={() => performAction(`delete-company-modal-id`)}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        label={config?.modalStrings?.labelOK}
        className={`small-button nok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={config?.modalStrings?.sureToProceed}
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
      />

      <Modal
        config={config}
        id={`view-company-modal-id`}
        title={config.modal.view.title}
        icon={<RxMagnifyingGlass className="edit" />}
        body={
          <ViewCompanyDetails
            id="the-card-vcd"
            currentCompanyItem={functions.filterRelevantProps(compItem)}
            config={config}
            copyContent={functions.copyContent}
          />
        }
        onClick={() => {}}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={``}
        hasConfirmBtn={false}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminCompaniesState } = state;

  return {
    companyWasDeleted: adminCompaniesState.companyWasDeleted,
    orderedList: adminCompaniesState.orderedList,
    orders: adminCompaniesState.orders,
    companiesIntendedCounter: adminCompaniesState.companiesIntendedCounter,
    companiesCounter: adminCompaniesState.companiesCounter,
    modalMustClose: adminCompaniesState.modalMustClose,
    compItem: adminCompaniesState.compItem,
    loadingErrors: adminCompaniesState.loadingErrors,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminCompaniesState, loader } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getTheCompaniesList: adminCompaniesState.getTheCompaniesList,
    setDeleteFlagState: adminCompaniesState.setDeleteFlagState,
    updateOrderState: adminCompaniesState.updateOrderState,
    setIsACompanyEdition: adminCompaniesState.setIsACompanyEdition,
    defineCompanyToEdit: adminCompaniesState.defineCompanyToEdit,
    saveCompItemGlobally: adminCompaniesState.saveCompItemGlobally,
    handleModalState: adminCompaniesState.handleModalState,
    deleteACompany: adminCompaniesState.deleteACompany,
    handleCompaniesListCounterAndOrder:
      adminCompaniesState.handleCompaniesListCounterAndOrder,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
