import React, { Fragment, useEffect, useState } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { functions } from './helpers';
import { AddDeviceProps } from './interface';
import { State, City } from 'country-state-city';
import ZipCodes from 'zipcodes';
import {
  Template,
  Label,
  SwitchBtn,
  Input,
  Button,
  Modal,
  AutoSuggest,
} from 'business/modules/admin/common';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { RiSave3Fill, RiEdit2Line } from 'react-icons/ri';
import { HiOutlineXCircle } from 'react-icons/hi';
import './styles.scss';

const COUNTRY_CODE = 'US';

const AddDevice: React.FC<AddDeviceProps> = ({ createInstallerDevice }) => {
  const history = useHistory();
  const [autosuggestKey, setKey] = useState('init');
  const [devData, setDevData] = useState<any>(config?.devState);
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errors, setErrors]: any = useState(null);
  const [currentField, setCurrentField] = useState('');
  const [formValues, setFormValues] = useState<any>(config.states.formValues);
  const [allStates, setAllStates]: any = useState([]);
  const [allCities, setAllCities]: any = useState([]);
  const [allCityZipCodes, setAllCityZipCodes]: any = useState([]);
  const [dataArrs, setDataArrs] = useState<any>({
    states: [],
    cities: [],
    zips: [],
  });
  const [suggs, setSuggests] = useState<any>({
    device_state: '',
    device_city: '',
    device_zipcode: '',
  });

  const resetKey = (newKey: string) => {
    setKey(newKey);
  };

  const formatZipCodes = (zipCodes: any) => {
    return zipCodes.map((zpCode: any) => {
      const { zip } = zpCode;
      return {
        ...zpCode,
        value: zip,
        label: zip,
      };
    });
  };

  const formatLocations = (data: any) => {
    return data.map((item: any) => {
      const { name, isoCode } = item;
      return {
        ...item,
        label: name,
        value: isoCode ? isoCode : name,
      };
    });
  };

  const retrieveList = (field: string) => {
    switch (field) {
      case 'device_state':
        return allStates;
      case 'device_city':
        return allCities;
      case 'device_zipcode':
        return allCityZipCodes;
      default:
        return [];
    }
  };

  const returnValue = (formField: any) => {
    return formField === `device_state`
      ? formValues?.device_state?.name
      : formField === `device_city`
      ? formValues?.device_city?.name
      : formField === `device_zipcode`
      ? formValues?.device_zipcode?.value
      : ``;
  };

  const initAllStates = () => {
    let allStates: any = State.getStatesOfCountry(COUNTRY_CODE);
    allStates = formatLocations(allStates);

    setAllStates(allStates);

    const stateNames = allStates.map((elem: any) => elem.name);
    setDataArrs({ ...dataArrs, states: stateNames });

    return allStates;
  };

  const prepareFields = () => {
    initAllStates();
  };

  const grabSuggestion = (id: string, currentSuggestion: string) => {
    //mark which input is being selected, in order to handle the error display

    setCurrentField(id);
    setSuggests({ ...suggs, [id]: currentSuggestion });
  };

  const validateInputtedVals = (suggs: any) => {
    switch (currentField) {
      case 'device_state':
        if (dataArrs?.states?.includes(suggs?.device_state)) {
          setErrors({
            ...errors,
            device_state: '',
          });
        } else {
          setErrors({
            ...errors,
            device_state: 'Please select a state from the list',
          });
        }
        break;
      case 'device_city':
        if (dataArrs?.cities?.includes(suggs?.device_city)) {
          setErrors({
            ...errors,
            device_city: '',
          });
        } else {
          setErrors({
            ...errors,
            device_city: 'Please select a city from the list',
          });
        }
        break;
      case 'device_zipcode':
        if (dataArrs?.zips?.includes(suggs?.device_zipcode)) {
          setErrors({
            ...errors,
            device_zipcode: '',
          });
        } else {
          setErrors({
            ...errors,
            device_zipcode: 'Please select a zipcode from the list',
          });
        }
        break;

      default:
        break;
    }
  };

  const handleInput = (event: any) => {
    let name: string = event.target.name;
    let value: any = event.target.value;

    let validate = functions.handleErrors(name, value);

    setErrors({ ...errors, [name]: validate });

    setDevData({ ...devData, [name]: value });
  };

  const handleStatus = () => {
    setSwitchValue(!switchValue);
  };

  const returnVal = (item: string) => {
    return devData[item as keyof typeof devData];
  };

  const preventEmptyData = (newDev: any) => {
    let emptyFields: any = [];

    if (newDev) {
      Object.entries<any>(newDev).map(([key, val], i) => {
        if (
          key !== 'code' &&
          typeof val === 'string' &&
          val.trim().length === 0
        ) {
          emptyFields.push(key);
        } else if (key !== 'code' && typeof val !== 'string') {
        }
      });

      if (emptyFields.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const validateEmptyFieldsOrErrors = () => {
    let areThereEmptyFields: any;

    areThereEmptyFields = preventEmptyData(devData);

    let thereAreErrors: any = [];

    if (errors) {
      thereAreErrors = Object.values(errors).filter(
        (item: any) => item.length > 0,
      );
    }

    if (
      !areThereEmptyFields &&
      thereAreErrors.length === 0 &&
      Object.values(formValues?.device_state).length > 0 &&
      Object.values(formValues?.device_city).length > 0 &&
      Object.values(formValues?.device_zipcode).length > 0
    ) {
      setDisabled(false);
    } else if (
      areThereEmptyFields ||
      thereAreErrors.length > 0 ||
      Object.values(formValues?.device_state).length === 0 ||
      Object.values(formValues?.device_city).length === 0 ||
      Object.values(formValues?.device_zipcode).length === 0
    ) {
      setDisabled(true);
    }
  };

  const saveDev = async () => {
    const newDev: any = {
      macAdress: devData?.macaddress,
      devName: devData?.name,
      devCity: formValues?.device_city?.name,
      devState: formValues?.device_state?.name,
      devZipcode: formValues?.device_zipcode?.value,
      status: switchValue,
    };

    let response: any = await createInstallerDevice(newDev);

    if (response?.statusCode === 200) {
    } else {
    }
  };

  const cancelAddDev = () => {
    setDevData({
      name: '',
      macaddress: '',
    });

    setSwitchValue(false);

    setFormValues(config.states.formValues);

    setErrors();
  };

  useEffect(() => {
    if (formValues?.device_state?.label) {
      const { name, value } = formValues.device_state;
      let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
      tempCities = formatLocations(tempCities);
      setAllCities([...tempCities]);

      //to posterior comparison - handle suggestion errs:
      const cityNames = tempCities.map((elem: any) => elem.name);

      setDataArrs({ ...dataArrs, cities: cityNames });
    }
  }, [formValues?.device_state?.label]);

  useEffect(() => {
    if (formValues?.device_city?.label) {
      const { name, stateCode, latitude, longitude } = formValues.device_city;
      let tempZipCodesByCoords: any = ZipCodes.lookupByCoords(
        latitude,
        longitude,
      );
      let tempZipCodes: any = ZipCodes.lookupByName(name, stateCode);
      tempZipCodes = formatZipCodes(
        tempZipCodes && tempZipCodes.length > 0
          ? tempZipCodes
          : [tempZipCodesByCoords],
      );

      setAllCityZipCodes([...tempZipCodes]);

      //to posterior comparison:
      const zipNums = tempZipCodes.map((elem: any) => elem.zip);

      setDataArrs({ ...dataArrs, zips: zipNums });
    }
  }, [formValues?.device_city?.label]);

  useEffect(() => {
    //if the suggestion is empty , empty also the formvalue to display the error
    validateInputtedVals(suggs);
  }, [suggs]);

  useEffect(() => {
    prepareFields();
  }, []);

  useEffect(() => {
    validateEmptyFieldsOrErrors();
  }, [errors, formValues, devData]);

  return (
    <Template
      id="installers-devs--add"
      title={config.add.title}
      goBack={() => {
        history.push(
          '/admin/dashboard/companies-list/company/installers/devices',
        );
      }}
    >
      <BreadcrumbsItem to={config.add.route}>
        {config.add.breadcrumbItem}
      </BreadcrumbsItem>

      <div className="add-installer-dev-screen">
        <div className="jumbotron spec">
          <h4 className="centered">{config.add.mainTitle} </h4>
          <p className="lead centered card-top-ft">{config.add.pText}</p>
          <hr className="my-4" />

          <div className="container pdg">
            <div className="row d-flex justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1 left-side">
                <div className="form-group dev-fg">
                  <div id={`dev-install-status-elem1`}>
                    <div id="switch--add-dev">
                      <Label className={`label-cmp-5`}>{`Status`}</Label>
                      <SwitchBtn
                        className=""
                        id={''}
                        value={switchValue}
                        onChange={handleStatus}
                      />{' '}
                    </div>
                  </div>

                  {config.fields.map((item: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <Label className={`label-cmp-5`}>
                          {functions.formatLabel(item?.name)}{' '}
                          <span className="required">(required)</span>
                        </Label>

                        <Input
                          className={`${
                            errors && errors[item.name] ? `class-err` : ``
                          }`}
                          name={item?.name}
                          type={item?.type}
                          id={`id-elem-${item?.name}`}
                          placeholder={`Enter ${functions.formatLabel(
                            item?.name,
                          )} ${functions.isDev(item?.name)}`}
                          value={returnVal(item?.name)}
                          onChange={handleInput}
                        />
                        <div className="render-error">
                          {errors && errors[item?.name]
                            ? errors[item?.name]
                            : ''}
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1 right-side">
                {config.formConfig.map((formField: any, index: number) => {
                  return (
                    <div className={formField.className} key={index}>
                      <div className="input-group">
                        <label className={`lead label-cmp-5`}>
                          {formField.label}{' '}
                          <span className="required">(required)</span>
                        </label>
                      </div>
                      {allStates.length > 0 && (
                        <div
                          key={autosuggestKey}
                          id="autosuggest--add--inst"
                          className={
                            errors &&
                            errors.hasOwnProperty(formField?.field) &&
                            errors[formField?.field].length > 0
                              ? 'auto-sugg-errclass'
                              : ''
                          }
                        >
                          <AutoSuggest
                            grabSuggestion={grabSuggestion}
                            dataWasCleared={false}
                            id={formField.field}
                            handleChange={(newState: any) => {
                              setFormValues((prevState: any) => {
                                return {
                                  ...prevState,
                                  [formField.field]: { ...newState },
                                };
                              });
                            }}
                            data={retrieveList(formField.field)}
                            value={returnValue(formField.field)}
                            {...formField.fields}
                          />
                        </div>
                      )}
                      <div className="render-error">
                        {errors && errors[formField.field]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="row d-flex justify-content-center btns-mrg">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1 left-side"></div>

              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1 right-side">
                <Button
                  className="small-button dismiss-btn float-right-btn"
                  type="button"
                  onClick={() => {
                    cancelAddDev();
                    setFormValues(config.states.formValues);
                    resetKey('reinit');

                    setTimeout(() => {
                      resetKey('reinit_');
                    }, 1);
                  }}
                >
                  <span className="btn-label btn-child-ft">
                    {' '}
                    <HiOutlineXCircle className="cancel" />
                    CANCEL
                  </span>
                </Button>

                <Button
                  className="small-button ok float-right-btn mr1"
                  dataToggle="modal"
                  dataTarget={`#save-new-dev--target`}
                  type="button"
                  disabled={disabled}
                >
                  <span className="btn-label btn-child-ft">
                    <RiSave3Fill className="edit" /> SAVE{' '}
                  </span>
                </Button>
              </div>
            </div>

            {disabled && (
              <div className="row d-flex justify-content-center">
                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-12 pdg">
                  <div className="alert alert-info mt-2" role="alert">
                    {config.strings.warning}
                  </div>{' '}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`save-new-dev--target`}
        title={config.modalStrings.edit}
        icon={<RiEdit2Line className="edit" />}
        body={config.modalStrings.aboutToUpdate}
        label={config.modalStrings.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={config.modalStrings.sureToProceed}
        hasConfirmBtn={true}
        warning={config.modalStrings.noUndo}
        onClickCancel={() => {
          cancelAddDev();
          setFormValues(config.states.formValues);
          resetKey('clear');

          setTimeout(() => {
            resetKey('clear_');
          }, 1);
        }}
        onClick={saveDev}
        //handle modal automatic closing when
        //there is a real dev creation going on:
        /* modalMustClose={modalMustClose}
        handleModalState={handleModalState}*/
        modalMustClose={false}
        handleModalState={() => {}}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminInstallerDevsState } = state;
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { adminInstallerDevsState } = dispatch;
  return {
    createInstallerDevice: adminInstallerDevsState.createInstallerDevice,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDevice);
