import React, { useState, useRef, useEffect } from 'react';
import { DetailsProps } from './interface';
import { FaFan } from 'react-icons/fa';
import { IoMdSwitch } from 'react-icons/io';
import { Bottom, MCConfigs, Devices } from './components';
import { Upload } from 'business/modules/admin/common';
import './styles.scss';

const Details: React.FC<DetailsProps> = ({
  item,
  config,
  manualControlData,
  eachUserDevsLoaded2,
  eachUserDevs2,
}) => {
  const [data, setData] = useState<any>();
  const [extended, setExtended] = useState<boolean>(false);
  const [mcExtended, setMcExtended] = useState<boolean>(false);
  const systemRefV2 = useRef<any>(null);
  const mcRef = useRef<any>(null);

  const values: any = {
    first_name: item.first_name,
    last_name: item.last_name,
    emails: item.emails[0].email,
    addresses: item.addresses[0].address_line_one.concat(
      item.address_line_two ? item.addresses[0].address_line_two : '',
    ),
    phones_numbers: item.phones_numbers[0].phone,
  };

  const labels: any = {
    dmst: 'Damper',
    fcst: 'Fan Connect',
    cfst: 'Central Fan',
    freshConnect: 'FreshConnect',
  };

  const returnLabel = (label: string) => {
    return labels[label as keyof typeof labels];
  };

  const scrollDivToTop = () => {
    if (systemRefV2 && systemRefV2.current) {
      systemRefV2.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const scrollToTop = () => {
    if (mcRef && mcRef.current) {
      mcRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const toggleSection = (type: string) => {
    if (type === 'devs') {
      setExtended(!extended);
    }
    if (type === 'mc') {
      setMcExtended(!mcExtended);
    }
  };

  const clickToUpload = () => {
    return document?.getElementById('file-input-id-c')?.click();
  };

  const handleImgErr = (event: any) => {
    event.target.src = '/avatar.png';
  };

  const handleUserInfo = (elem: any) => {
    return config.labels[elem.info as keyof typeof config.labels];
  };

  const handleValue = (key: string, elem: any) => {
    return values[key as keyof typeof values];
  };

  const handleSystemKey = (key: string) => {
    return config.keys[key as keyof typeof config.keys] || '';
  };

  const handleItemVal = (value: any, key: string) => {
    if (typeof value === 'string' || typeof value === 'boolean') {
      return value.toString();
    } else {
      if (key === `details`) {
        return (
          <div>
            <div className="row">
              <div className="col-6 spec--col-prop">
                <span className="system-k">
                  {' '}
                  {config.strings.detailsStatus}
                </span>{' '}
                <br />{' '}
                <span className="system-k">{config.strings.peripherals}</span>
              </div>

              <div className="col">
                <span className="lead-sm">
                  {value.status ? value.status : `No data`}
                </span>
                <br />
                <span className="lead-sm">
                  {value.settings.conf.cdvs.length}
                </span>
              </div>
            </div>
          </div>
        );
      } else if (key === `device_city`) {
        return value?.name;
      } else if (key === 'device_zipcode') {
        return value?.zip;
      } else if (key === `device_state`) {
        return value?.name;
      }
    }
  };

  useEffect(() => {
    if (eachUserDevsLoaded2) {
      let current = eachUserDevs2[item.account_id];

      setData(current);
    }
  }, [eachUserDevsLoaded2, eachUserDevs2, item]);

  useEffect(() => {
    if (!extended) {
      scrollDivToTop();
    }
    return () => {};
  }, [extended]);

  useEffect(() => {
    if (!mcExtended) {
      scrollToTop();
    }
    return () => {};
  }, [mcExtended]);

  return (
    <div className={`container user-information-accordion-details`}>
      <div className={`row`}>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 no-left-p">
          {!item.picture.profile ? (
            <Upload
              type={`file`}
              id={`file-input-id-c`}
              name={`avatar`}
              accept={`image/png, image/jpeg`}
              onClick={clickToUpload}
              imgElement={
                <div className={`circle-avt dbmainb01`}>
                  <span className="init-styles-01">
                    {item.first_name[0]}
                    {item.last_name[0]}
                  </span>
                </div>
              }
              rowUpl={`row upload-01`}
              idUpl={`input-upload-c`}
              cClass={`col-5`}
            />
          ) : (
            <Upload
              type={`file`}
              id={`file-input-id-0001z`}
              name={`avatar`}
              accept={`image/png, image/jpeg`}
              onClick={clickToUpload}
              imgElement={
                <img
                  onError={handleImgErr}
                  src={item?.picture?.profile?.url}
                  alt="Card image cap"
                  className={`shared-img-styles ${`dm-width`}`}
                />
              }
              rowUpl={`row upload-02`}
              idUpl={`input-upload-z`}
              cClass={`col-6`}
            />
          )}

          <div className="row d-flex justify-content-center profile">
            <div className="col-12 no-left-p">
              {config?.formInfo.map((elem: any, index: number) => {
                return (
                  <div className={`container details-spc`} key={index}>
                    <div className="row details-spc dr-row">
                      <span className="lead-sm-bold">
                        {handleUserInfo(elem)}
                      </span>
                      <span className="lead-sm">
                        {handleValue(elem.info, item)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
          <div className={`devices-list-2`}>
            <div className="icon-div mb-3">
              <FaFan className="bigger-icon" />
            </div>
            <h5 className="card-title d-flex justify-content-center">{`Devices`}</h5>

            {!data ? (
              <> </>
            ) : data && data.length === 0 ? (
              <div className="container spec--col-prop spec--col-prop1">
                <div className="row d-flex pt12">
                  <div className="col-12 titles">
                    <h5 className="card-title lead card-title-sys">
                      {config.strings.accountInfo}
                    </h5>
                  </div>
                  <div className="col-12 titles">
                    <h6 className="card-subtitle mb-2 text-muted">
                      {item?.first_name}' s devices
                    </h6>
                  </div>
                  <div className="col-12 titles">
                    <p className="card-text">{config.strings.noDevs}</p>
                  </div>
                </div>
              </div>
            ) : data && Object.values(data).length === 0 ? (
              <div className="container spec--col-prop spec--col-prop1">
                <div className="row d-flex pt12">
                  <div className="col-12 titles">
                    <h5 className="card-title lead card-title-sys">
                      {config.strings.accountInfo}
                    </h5>
                  </div>
                  <div className="col-12 titles">
                    <h6 className="card-subtitle mb-2 text-muted">
                      {item?.first_name}' s devices
                    </h6>
                  </div>
                  <div className="col-12 titles">
                    <p className="card-text">{config.strings.noDevs}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`inner-dev-data1 sys-min-h-col ${
                  extended ? `extended-div` : ``
                }`}
                ref={systemRefV2}
              >
                {data &&
                  Object.values(data).length > 0 &&
                  Object.values(data).map((elm: any, index: number) => {
                    return (
                      <Devices
                        key={index}
                        index={index}
                        elm={elm}
                        config={config}
                        handleSystemKey={handleSystemKey}
                        handleItemVal={handleItemVal}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          {data && Object.values(data).length > 1 && (
            <Bottom extended={extended} onClick={() => toggleSection('devs')} />
          )}
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mcontrol-data">
          <div className={`devices-list-2`}>
            <div className="icon-div mb-3">
              <IoMdSwitch className="bigger-icon" />
            </div>
            <h5 className="card-title d-flex justify-content-center">{`Manual Control`}</h5>

            <div
              ref={mcRef}
              className={`inner-section inner-dev-data1 sys-min-h-col ${
                mcExtended ? `extended-div` : ``
              }`}
            >
              {!manualControlData ? (
                <> </>
              ) : manualControlData &&
                manualControlData[item?.account_id] &&
                manualControlData[item?.account_id]?.devs?.length > 0 ? (
                manualControlData[item?.account_id]?.devs.map(
                  (dev: any, index: number) => {
                    return dev?.config?.message ? (
                      <div
                        className="container spec--col-prop spec--col-prop1"
                        key={index}
                      >
                        <div className="row d-flex pt12">
                          <div className="col-12 titles">
                            <h5 className="card-title lead card-title-sys">
                              {dev?.system_name}
                            </h5>
                          </div>
                          <div className="col-12 titles">
                            <h6 className="card-subtitle mb-2 text-muted">
                              {config.strings.mcSettings}
                            </h6>
                          </div>
                          <div className="col-12 titles">
                            <p className="card-text">
                              {config.strings.noConfigs}
                            </p>
                          </div>
                        </div>{' '}
                      </div>
                    ) : (
                      <MCConfigs
                        key={index}
                        dev={dev}
                        index={index}
                        returnLabel={returnLabel}
                      />
                    );
                  },
                )
              ) : (
                <div className="container spec--col-prop spec--col-prop1">
                  <div className="row d-flex pt12">
                    {config?.sections?.manualCtr?.noConfigs.map(
                      (elem: any, index: number) => {
                        return (
                          <div className={elem.col} key={index}>
                            {elem.element}
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {manualControlData &&
            manualControlData[item?.account_id] &&
            manualControlData[item?.account_id].devs.length > 1 && (
              <Bottom
                extended={mcExtended}
                onClick={() => toggleSection('mc')}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default Details;
